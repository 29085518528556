var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-item" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header mb-5 px-4 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("a-icon", { staticClass: "arrow-left mr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("span", { staticClass: "mr-3 header-title" }, [_vm._v("Add Item")])], 1)], 1)], 1), _c("div", { staticClass: "body mx-5" }, [_c("a-row", [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { staticClass: "mb-5", attrs: { "lg": 24, "xl": 8 } }, [_c("a-card", [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", [_c("cf-select-input", { attrs: { "form-item": "", "rules": "required", "label": "Item Code", "placeholder": "Choose Item Code", "reference": "cf.common.item-masters", "source": "itemCode", "search-by": "ItemCode", "filter-type": "StartsWith", "option-filter": _vm.filterIsInactiveOption, "delete-filter": "IsInactive", "api-not-get-paging": "", "all-in-value": "" }, on: { "change": _vm.onItemChange } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", [_c("text-input", { attrs: { "form-item": "", "label": "Description", "value": _vm.createForm.description, "disabled": true, "span": 24 } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", [_c("text-input", { attrs: { "form-item": "", "label": "Stock UoM", "value": _vm.createForm.stockUoM, "disabled": true, "span": 24 } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", [_c("cf-select-input", { attrs: { "form-item": "", "rules": "required", "label": "Default Status", "placeholder": "Choose Status", "reference": "cf.common.status", "source": "id", "source-label": "description", "search-by": "Description", "filter-type": "StartsWith", "option-filter": _vm.filterIsInactiveOption, "api-not-get-paging": "", "all-in-value": "", "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("statusId", $event.id);
    } } })], 1)], 1)], 1)], 1)], 1), _c("a-col", { staticClass: "mb-5", attrs: { "lg": 24, "xl": 16 } }, [_c("a-card", [_c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Specification", "disabled": true, "span": 24, "value": _vm.createForm.specCode } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Specification Description", "disabled": true, "span": 24, "value": _vm.createForm.specDesc } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "form-item": "", "rules": "required|max:10", "label": "Label Description 1", "placeholder": "Type Label Description 1", "max-length": 10, "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc1", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("text-input", { attrs: { "form-item": "", "rules": "max:15", "label": "Label Description 2", "placeholder": "Type Label Description 2", "max-length": 15, "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("labelDesc2", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("cf-select-input", { attrs: { "all-in-value": "", "api-not-get-paging": "", "delete-filter": "IsInactive", "filter-type": "StartsWith", "form-item": "", "label": "Container Type", "placeholder": "Choose Container Type", "reference": "cf.common.cont-types", "rules": "required", "search-by": "Code", "source": "id", "source-label": "code" }, on: { "change": _vm.onContainerTypeChange } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Container Type Description", "value": _vm.createForm.containerTypeDesc, "disabled": true, "span": 24 } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("auto-complete-input", { attrs: { "form-item": "", "label": "Customer", "placeholder": "Choose Customer", "reference": "cf.common.customers", "source": "customerNumber", "search-by": "CustomerNumber", "filter-type": "StartsWith", "delete-filter": "IsInactive", "cols": _vm.colCustomers, "api-not-get-paging": "", "all-in-value": "" }, on: { "searchValueChange": _vm.onCustomerSearchValueChange, "change": _vm.onCustomerChange } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Customer Name", "disabled": true, "value": _vm.createForm.customerName } })], 1)], 1)], 1)], 1)], 1)], 1), _c("div", { staticClass: "ant-modal-footer p-3" }, [_c("a-button", { attrs: { "disabled": _vm.isLoading }, on: { "click": _vm.onBack } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "disabled": _vm.isLoading, "loading": _vm.isLoading }, on: { "click": function($event) {
      return handleSubmit(_vm.onCreateClick);
    } } }, [_vm._v("Create")])], 1)];
  } }]) })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var CreateItem_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "CreateItem",
  inject: ["resourceProps"],
  data() {
    const [itemProps, qmiManufacProps, qpmSpecProps] = this.resourceProps;
    return {
      createForm: {},
      entity: {},
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "item",
          title: "Item",
          path: "/container-filling/item"
        },
        {
          key: "create",
          title: "Add Item",
          path: "/container-filling/item/create"
        }
      ],
      itemProps,
      qmiManufacProps,
      qpmSpecProps,
      isLoading: false,
      colCustomers: [{ name: "customerName", span: 18 }]
    };
  },
  destroyed() {
    this.itemProps.crud.clearList();
    this.qmiManufacProps.crud.clearList();
    this.qpmSpecProps.crud.clearList();
  },
  methods: {
    onBack() {
      this.$router.push("/container-filling/item");
    },
    filterIsInactiveOption(option) {
      return !option.isInactive;
    },
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.itemProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async onCreateClick() {
      this.isLoading = true;
      try {
        const response = await this.itemProps.crud.submitEntity("create");
        this.$router.push("/container-filling/item/" + response.data.id);
        this.$nextTick(() => this.$notification.success({
          message: `Specification ${this.createForm.specCode} has been added to Container Filling. Please ensure you update the Screen Sequence for the tests.`
        }));
      } finally {
        this.isLoading = false;
      }
    },
    async fetchListManufac() {
      const qmiManufacQueryParams = `?fe=(ItemCode StartsWith \`${this.createForm.code.trim()}\`)`;
      await this.qmiManufacProps.crud.fetchList(`${this.qmiManufacProps.apiUrl}/cf/common/qmi-manufac${qmiManufacQueryParams}`, void 0, false);
    },
    async onItemChange(value) {
      if (!value) {
        this.storeValue("code", "");
        this.storeValue("description", "");
        this.storeValue("stockUoM", "");
        this.storeValue("specCode", "");
        this.storeValue("specDesc", "");
        return;
      }
      this.isLoading = true;
      const { itemCode, itemDesc, stockUom } = value;
      this.storeValue("code", itemCode);
      this.storeValue("description", itemDesc);
      this.storeValue("stockUoM", stockUom);
      await this.fetchListManufac();
      const specs = this.qmiManufacProps.crud.getList();
      if (specs && specs[0]) {
        const { specCode, description } = specs[0];
        this.storeValue("specCode", specCode);
        this.storeValue("specDesc", description);
      } else {
        this.storeValue("specCode", void 0);
        this.storeValue("specDesc", void 0);
      }
      this.isLoading = false;
    },
    onContainerTypeChange(value) {
      const { id, description } = value;
      this.storeValue("containerTypeId", id);
      this.storeValue("containerTypeDesc", description);
    },
    onCustomerSearchValueChange(value) {
      this.storeValue("customerNo", value);
    },
    onCustomerChange(value) {
      if (!value) {
        this.storeValue("customerName", "");
        this.storeValue("customerAddress1", "");
        this.storeValue("customerAddress2", "");
        return;
      }
      this.storeValue("customerNo", value.customerNumber);
      this.storeValue("customerName", value.customerName);
      this.storeValue("customerAddress1", value.address1);
      this.storeValue("customerAddress2", value.address2);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7de54305", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateItem = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-item" }, [_c("resource", { attrs: { "name": "cf.common.item-masters", "api-url": _vm.apiUrl, "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "name": "cf.common.cont-types", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.common.status", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "cf.common.customers", "api-url": _vm.apiUrl, "resource-id-name": "customerNumber" } }), _c("resource", { attrs: { "name": ["cf.items", "cf.common.qmi-manufac", "cf.common.qpm-spec"], "api-url": _vm.apiUrl, "redirect-route": "'/container-filling/item/create'", "resource-id-name": ["id", "specCode", "specCode"] } }, [_c("create-item")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateItem
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
